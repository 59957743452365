import React from 'react';
import './contact.css';
import msg_icon from '../../assests/msg-icon.png';
import mail_icon from '../../assests/mail-icon.png';
import phone_icon from '../../assests/phone-icon.png';
import location_icon from '../../assests/location-icon.png';
import white_arrow from '../../assests/white-arrow.png';

const Contact = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "7a6cefd8-1d60-46e1-bf19-4a34d18d5a37");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div className="contact">
      <div className="contact-col">
        <h3>
          Send us a message <img src={msg_icon} alt="" />
        </h3>
        <p>
          We stay in constant communication with our customers until the job is done.
          To get a free quote, or if you have questions or special requests, just drop us a line.
          Home repair, hvac, painting handyman services.
        </p>
        <ul>
          <li>
            <img src={mail_icon} alt="" />JCTKServices@gmail.com
          </li>
          <li>
            <img src={phone_icon} alt="" />813-770-1065
          </li>
          <li>
            <img src={location_icon} alt="" />Bradenton, Florida, United States
          </li>
        </ul>
      </div>
      <div className="contact-col">
        <form onSubmit={onSubmit}>
          <label htmlFor="name">Your Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Enter your name"
            autoComplete="name"
            required
          />
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Enter your mobile number"
            autoComplete="tel"
            required
          />
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            autoComplete="email"
            required
          />
          <label htmlFor="message">Write your message here</label>
          <textarea
            id="message"
            name="message"
            rows="6"
            placeholder="Enter your message"
            autoComplete="off"
            required
          ></textarea>
          <button type="submit" className="btn dark-btn">
            Submit now <img src={white_arrow} alt="" />
          </button>
        </form>
        <span>{result}</span>
      </div>
    </div>
  );
};

export default Contact;
