import React, { useEffect, useState } from "react";
import client, { urlFor } from '../../client';
import Title from "../Title/Title";
import Slider from "react-slick";
import "./WorkSectionSix.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WorkSectionSix = () => {
  const [section, setSection] = useState(null);

  useEffect(() => {
    client.fetch(
      `*[_type == "workSectionSix"][0]{
        title,
        description,
        beforeImage,
        afterImage,
        thirdImage,
        fourthImage,
        video
      }`
    )
    .then((data) => setSection(data))
    .catch(console.error);
  }, []);

  if (!section) return <div>Loading...</div>;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const images = [
    section.beforeImage ? { src: urlFor(section.beforeImage).url(), alt: "Before" } : null,
    section.afterImage ? { src: urlFor(section.afterImage).url(), alt: "After" } : null,
    section.thirdImage ? { src: urlFor(section.thirdImage).url(), alt: "Third" } : null,
    section.fourthImage ? { src: urlFor(section.fourthImage).url(), alt: "Fourth" } : null
  ].filter(Boolean);

  return (
    <section className="pad-t60 pad-b50">
      <Title
        subTitle="Washer Box"
        title="Before And After With Drywall Repair"
      />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p className="text-center">{section.description}</p>
          </div>
        </div>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="slider-item">
              <img className="img-responsive" src={image.src} alt={image.alt} />
            </div>
          ))}
          {section.video && (
            <div className="slider-item">
              <video controls className="img-responsive">
                <source src={urlFor(section.video).url()} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </Slider>
      </div>
    </section>
  );
}

export default WorkSectionSix;
