// Services.jsx
import React, { useEffect, useState } from 'react';
import client, { urlFor } from '../../client'; // Adjust the import path if needed
import './services.css';

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const query = '*[_type == "services"]{ _id, title, description, image }'; // Ensure the query matches your schema
        const servicesData = await client.fetch(query);
        setServices(servicesData);
      } catch (error) {
        console.error('Error fetching services:', error);
        setError('Failed to load services. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  if (loading) return <div className='loading'>Loading...</div>;
  if (error) return <div className='error'>{error}</div>;

  return (
    <div className='services-container'>
      <h2 className='services-title'>
        We Provide on demand Handyman, Plumbing & Electrical Repair, Window & Door, 
        House Painting & Wallpaper, Hardwood & Laminate Floor, Drywall & Stucco Repair, 
        Carpentry Woodwork, Tiles & Concrete Installation
      </h2>
      <div className='services'>
        {services.length > 0 ? (
          services.map((service) => (
            <div className='service' key={service._id}>
              <img src={urlFor(service.image)} alt={service.title} /> {/* No need to call .url() */}
              <p className='service-title'>{service.title}</p>
              <p className='service-desc'>{service.description}</p>
            </div>
          ))
        ) : (
          <p className='no-services'>No services available at the moment.</p>
        )}
      </div>
    </div>
  );
};

export default Services;
