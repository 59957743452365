import React, {useEffect} from 'react'
import { animateScroll as scroll } from 'react-scroll';
import './hero.css'
import dark_arrow from '../../assests/dark-arrow.png'
import { Link } from 'react-scroll';

const Hero = () => {
  useEffect(() => {
    const scrollTo = localStorage.getItem('scrollTo');
  
    if (scrollTo) {
      scroll.scrollToTop();
      localStorage.removeItem('scrollTo');
    }
  }, []);

  return (
    <div className='hero container'>
      <div className='hero-text'>
        <h1>Your Trusted Home Repair Experts</h1>
        <p>Diving into the Depths of Home Repair: Plumbing, Electrical, HVAC, and Beyond - Discover Our Unmatched Expertise and Dedication to Elevating Your Home Maintenance Experience</p>
        <Link to='contact' smooth={true} offset={-350} duration={500}><button className='btn'>Get A Free Estimate <img src={dark_arrow} alt="" /></button></Link>
      </div>
    </div>
  )
}

export default Hero
