import React, { useEffect, useState } from 'react';
import client, { urlFor } from '../../client'; // Adjust the import path if needed
import './WorkSectionFour.css';
import Title from '../Title/Title';

const WorkSectionFour = () => {
  const [workSection, setWorkSection] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWorkSection = async () => {
      try {
        const query = '*[_type == "workSectionFour"][0]';
        const data = await client.fetch(query);
        console.log("Fetched data:", data);
        setWorkSection(data);
      } catch (error) {
        console.error('Error fetching work section:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkSection();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (!workSection) return <div>No data available.</div>;

  const images = [
    workSection.beforeImage && { src: urlFor(workSection.beforeImage).url(), alt: "Before" },
    workSection.afterImage && { src: urlFor(workSection.afterImage).url(), alt: "After" },
    workSection.thirdImage && { src: urlFor(workSection.thirdImage).url(), alt: "Third" },
    workSection.fourthImage && { src: urlFor(workSection.fourthImage).url(), alt: "Fourth" },
  ].filter(Boolean);

  return (
    <div className="work-section-four-container">
      <Title title={workSection.title} />
      <div className="work-images">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={image.alt}
            className="work-img"
          />
        ))}
      </div>
      {workSection.video && (
        <video controls className="work-video">
          <source src={urlFor(workSection.video).url()} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      <div className="work-right">
        <h2>{workSection.description}</h2>
      </div>
    </div>
  );
};

export default WorkSectionFour;
