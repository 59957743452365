import React from 'react'
import './subhero.css';

const SubHero = ({ title, subtitle}) => {
    return (
        <div className='appointment_hero'>
        </div>
    );
}

export default SubHero
