import React from 'react'
import './footer.css'

const Footer = () => {
    return (
        <div className='footer'>
            <p>@ 2024 JCTK Services. All rights reserved</p>
            <ul>
                <li>Terms Of Services</li>
                <li>Privacy Policy</li>
            </ul>
        </div>
    );
}

export default Footer
