import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate, useLocation,  } from 'react-router-dom';
import './navbar.css'
import logo from '../../assests/JCTK-logo.png'
import menu_icon from '../../assests/menu-icon.png'
import { Link as  Element, scroller } from 'react-scroll';

const Navbar = () => {

  const [sticky, setSticky] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const navbarRef = useRef(null);

  const scrollTarget = (target, navbarHeight) => scroller.scrollTo(target, { offset: -320, smooth: true, duration: 700});

  const scrollToPage = async (target) => {
      if (location.pathname !== '/') {
          await navigate('/');
      }
      const navbarHeight = document.querySelector('.navbar-container').offsetHeight;
      scrollTarget(target,navbarHeight);
  };

  

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 50 ? setSticky(true) : setSticky(false)
    })
  }, []);

  
  const toggleMenu = ()=>{
    mobileMenu ? setMobileMenu(false) : setMobileMenu(true);
  }
  
  return (
    <nav className={`navbar-container  ${sticky ? 'dark-nav' : ''}`} ref={navbarRef}>
      <Link to="/">
        <img src={logo} alt="" className='logo' />
      </Link>
      <ul className={mobileMenu?'': 'hide-mobile-menu'}>
        <li onClick={() => scrollToPage("hero")}><Link to='hero' smooth={"true"} offset={0} duration={500} onClick={() => {if (window.innerWidth <= 768) setMobileMenu(false)}}>Home</Link></li>
        <li style={{cursor: "pointer"}} onClick={() => scrollToPage("services")}><Link to='services' smooth={"true"} offset={-350} duration={500} onClick={() => { if (window.innerWidth <= 768) setMobileMenu(false) }}>Services</Link></li>
        <li style={{ cursor: "pointer" }} onClick={() => scrollToPage("about")}><Link to='about' smooth={"true"} offset={-150} duration={500} onClick={() => { if (window.innerWidth <= 768) setMobileMenu(false) }}>About Us</Link></li>
        <li><Link to="/work">Gallery</Link></li>
        <li><Link to="/appointment">Appointment</Link></li>
        <li style={{cursor: "pointer"}} onClick={() => scrollToPage("testimonials")}><Link to='testimonials' smooth={"true"} offset={-350} duration={500} onClick={() => { if (window.innerWidth <= 768) setMobileMenu(false) }}>Testimonials</Link></li>
        <li style={{cursor: "pointer"}} onClick={() => scrollToPage("contact")}><Link to='contact' smooth={"true"} offset={-260} duration={500} onClick={() => { if (window.innerWidth <= 768) setMobileMenu(false) }}><button className='btn'>Contact Us</button></Link></li>
      </ul>
      <img src={menu_icon} alt="" className='menu-icon' onClick={toggleMenu}/>
    </nav>
  );
}

export default Navbar