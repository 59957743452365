import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import client, { urlFor } from '../../client'; // Adjust the import path if needed
import './gallery.css';
import placeholderImage from '../../assests/Screenshot.jpeg'; // Adjust the path to your placeholder image

const Gallery = () => {
  const [works, setWorks] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchWorks = async () => {
      try {
        const query = `*[_type == "work"]{
          _id,
          title,
          description,
          image,
          "videoUrl": video.asset->url
        }`;
        const worksData = await client.fetch(query);
        setWorks(worksData);
      } catch (error) {
        console.error('Error fetching works:', error);
      }
    };

    fetchWorks();
  }, []);

  const handleViewMore = () => {
    navigate('/work');
  };

  return (
    <div className="gallery">
      <div className="photos">
        {works.map((work) => (
          <div key={work._id} className="photo-item">
            <div className="media-container">
              {work.videoUrl ? (
                <>
                  <video controls muted loop poster={work.image ? urlFor(work.image).url() : placeholderImage}>
                    <source src={work.videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </>
              ) : (
                <img
                  src={work.image ? urlFor(work.image).url() : placeholderImage}
                  alt={work.title}
                />
              )}
            </div>
            <h3>{work.title}</h3>
            <p>{work.description}</p>
          </div>
        ))}
      </div>
      {location.pathname !== '/work' && (
        <button className="btn dark-btn" onClick={handleViewMore}>
          View More
        </button>
      )}
    </div>
  );
};

export default Gallery;
