import React from 'react';
import Appointments from "../../Components/Appointments/Appointments";
import SubHero from "../../Components/pagehero/SubHero";
import Title from "../../Components/Title/Title";
import './Appointment.css';

const Appointment = ({ heroProps }) => {
  return (
    <div className="appointment">
      <SubHero title={heroProps.title} subtitle={heroProps.subtitle} />
      <div className="appointment-form">
        <Title
          subTitle={heroProps.subtitle}
          title={heroProps.title}
        />
        <Appointments />
      </div> 
    </div>
  );
}

export default Appointment;
