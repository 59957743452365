import React, { useEffect, useState } from 'react';
import client, { urlFor } from '../../client'; // Adjust the import path as needed
import './WorkSectionTwo.css';
import Title from '../Title/Title';

const WorkSectionTwo = () => {
  const [section, setSection] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.fetch('*[_type == "workSectionTwo"][0]'); // Replace with your actual query
        console.log("Fetched data:", data);
        setSection(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (!section) return <div>No data available.</div>;

  const images = [
    section.beforeImage && { src: urlFor(section.beforeImage).url(), alt: "Before" },
    section.afterImage && { src: urlFor(section.afterImage).url(), alt: "After" },
  ].filter(Boolean);

  return (
    <div className='work-section-two-container'>
      <Title
        title={section.title} // Use the title from the fetched data
      />
      <div className="work-images">
        {/* Display images */}
        {images.map((image, index) => (
          <img 
            key={index}
            src={image.src} 
            alt={image.alt} 
            className='work-img' 
          />
        ))}
      </div>
      {/* Display video if available */}
      {section.video && (
        <video controls className='work-video'>
          <source src={urlFor(section.video).url()} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      <div className="work-right">
        <h3>{section.description}</h3>
      </div>
    </div>
  );
};

export default WorkSectionTwo;
