import React, { useRef, useEffect, useState } from 'react';
import client, { urlFor } from '../../client';
import './testimonials.css';
import next_icon from '../../assests/next-icon.png';
import back_icon from '../../assests/back-icon.png';

const Testimonials = () => {
  const slider = useRef();
  const [testimonials, setTestimonials] = useState([]);
  const [error, setError] = useState("");

  const slideForward = () => {
    if (slider.current) {
      let tx = parseFloat(slider.current.style.transform.replace('translateX(', '').replace('%)', '')) || 0;
      if (tx > -100 * (testimonials.length - 1)) {
        tx -= 100; // Move by one slide width
        slider.current.style.transform = `translateX(${tx}%)`;
      }
    }
  };

  const slideBackward = () => {
    if (slider.current) {
      let tx = parseFloat(slider.current.style.transform.replace('translateX(', '').replace('%)', '')) || 0;
      if (tx < 0) {
        tx += 100; // Move by one slide width
        slider.current.style.transform = `translateX(${tx}%)`;
      }
    }
  };

  useEffect(() => {
    client
      .fetch('*[_type == "testimonial"]{name, location, image, review, rating}')
      .then((data) => setTestimonials(data))
      .catch((err) => {
        console.error(err);
        setError("Failed to load testimonials.");
      });
  }, []);

  const renderStars = (rating) => {
    return (
      <>
        {[...Array(5)].map((_, index) => (
          <span key={index} className={`star ${index < rating ? 'filled' : ''}`}>★</span>
        ))}
      </>
    );
  };

  return (
    <div className='testimonials'>
      {error && <div className="error-message">{error}</div>}
      {testimonials.length > 0 ? (
        <>
          <img src={next_icon} alt="Next" className='next-btn' onClick={slideForward} />
          <img src={back_icon} alt="Back" className='back-btn' onClick={slideBackward} />
          <div className="slider">
            <ul ref={slider}>
              {testimonials.map((testimonial, index) => (
                <li key={index}>
                  <div className='slide'>
                    <div className="user-info">
                      <img src={urlFor(testimonial.image)} alt={testimonial.name} />
                      <div>
                        <h3>{testimonial.name}</h3>
                        <span>{testimonial.location}</span>
                        <div className="rating">{renderStars(testimonial.rating)}</div>
                      </div>
                    </div>
                    <p>{testimonial.review}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : (
        <div className="coming-soon">Reviews Coming Soon</div>
      )}
    </div>
  );
};

export default Testimonials;
