// client.js
import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

// Retrieve environment variables
const projectId = process.env.REACT_APP_SANITY_PROJECT_ID;
const dataset = process.env.REACT_APP_SANITY_DATASET || "production";
const apiVersion = process.env.REACT_APP_SANITY_API_VERSION || "2024-07-31";
const useCdn = process.env.REACT_APP_SANITY_USE_CDN === "true";

// Check if the required environment variable is set
if (!projectId) {
  throw new Error("Configuration must contain `projectId`");
}

// Create a Sanity client instance
const client = createClient({
  projectId,
  dataset,
  apiVersion,
  useCdn,
});

// Create an image URL builder
const builder = imageUrlBuilder(client);

// Function to generate image URLs
export const urlFor = (source) => {
  if (!source) {
    console.error("No source provided for urlFor function");
    return null;
  }

  try {
    return builder.image(source); // Ensure to call .url() method
  } catch (error) {
    console.error("Error generating image URL:", error);
    return null;
  }
};

export default client;
