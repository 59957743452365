import React, {useEffect} from 'react';
import Gallery from '../../Components/Gallery/Gallery';
import SubHero from '../../Components/pagehero/SubHero';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import Title from '../../Components/Title/Title';
import WorkSection from '../../Components/WorkSection/WorkSection';
import Contact from '../../Components/Contact/Contact';
import Banner from '../../Components/Banner/Banner';
import WorkSectionTwo from '../../Components/WorkSectionTwo/WorkSectionTwo';
import WorkSectionThree from '../../Components/WorkSectionThree/WorkSectionThree';
import WorkSectionFour from '../../Components/WorkSectionFour/WorkSectionFour';
import WorkSectionFive from '../../Components/WorkSectionFive/WorkSectionFive';
import WorkSectionSix from '../../Components/WorkSectionSix/WorkSectionSix';
import './Work.css';

const Work = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <div className='work'>
      <Navbar />
      <SubHero title="Our Work" subtitle="From Carpet To Vinyl Before and After" />
      <WorkSection />
      <WorkSectionTwo />
      <WorkSectionThree />
      <WorkSectionFour />
      <WorkSectionFive />
      <WorkSectionSix />
      <Title
        subTitle="Our Work"
        title="From Carpet To Vinyl Before and After"
      />
      <Gallery />
      <Banner />
      <Contact />
      <Footer />
    </div>
  );
}

export default Work;
