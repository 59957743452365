import React, { useEffect, useState } from 'react';
import client, { urlFor } from '../../client'; // Adjust the import path if needed
import './workSectionThree.css';
import Title from '../Title/Title';

const WorkSectionThree = () => {
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const query = '*[_type == "imageGallery"]{images, title}'; // Fetch images and title
        const data = await client.fetch(query);
        // Flattening the array if you have multiple documents
        const allImages = data.flatMap(item => item.images);
        const galleryTitle = data.length > 0 ? data[0].title : 'Gallery'; // Get the title
        setImages(allImages);
        setTitle(galleryTitle);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div className='work-section-three-container'>
      <Title
        title={title} // Use the fetched title
      />
      <div className='grid'>
        {images.map((image, index) => (
          <div key={index} className='grid-item'>
            <img src={urlFor(image).url()} alt={`Gallery item ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default WorkSectionThree;
