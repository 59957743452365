import React from "react";
import Navbar from "./Components/Navbar/Navbar";
import Hero from "./Components/Hero/Hero";
import Services from "./Components/Services/Services";
import Title from "./Components/Title/Title";
import About from "./Components/About/About";
import Gallery from "./Components/Gallery/Gallery";
import Testimonials from "./Components/Testimonials/Testimonials";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import { Element } from "react-scroll";
import Banner from "./Components/Banner/Banner";

const App = () => {
  return (
    <>
      <Navbar />
      <Element name="hero">
        <Hero />
      </Element>
      <div className="container">
        <Title
          subTitle="JCTK SERVICES SIMPLY THE BEST OF HOME IMPROVEMENT’S"
          title="What We Offer"
        />
        <Element name="services">
          <Services />
        </Element>
        <Title subTitle="Who We Are" title="About JCTK Services" />
        <Element name="about">
          <About />
        </Element>
        <Title
          subTitle="Our Work"
          title="From Carpet To Vinyl Before and After"
        />
        <Gallery />
      </div>
      <Banner /> {/* Ensure this is outside the .container */}
      <div className="container">
        <Title subTitle="TESTIMONIALS" title="Hear From Our Happy Customers" />
        <Testimonials />
        <Title subTitle="Contact US" title="Get A Free Quote!" />
        <Contact />
      </div>
      <Footer />
    </>
  );
};

export default App;
