import React, { useEffect, useState } from 'react';
import mail_icon from '../../assests/mail-icon.png'
import phone_icon from '../../assests/phone-icon.png'
import location_icon from '../../assests/location-icon.png'
import './appointments.css';

const Appointments = () => {
    const [result, setResult] = useState("");
    const [status, setStatus] = useState("Submit");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");
        const form = e.target;
        const name = form.elements.first_name.value;
        const last_name = form.elements.last_name.value;
        const email = form.elements.email.value;
        const street_address = form.elements.street_address.value;
        const city = form.elements.city.value;
        const zip_code = form.elements.zip_code.value;
        const phone = form.elements.phone.value;
        const source = form.elements.source.value;  
        const inquiry = form.elements.inquiry.value;    
        
        try {
            const response = await fetch("http://localhost:8000/api/appointment", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify({ 
                    first_name: name,
                    last_name,
                    email,
                    street_address,
                    city,
                    zip_code,
                    phone,
                    source,
                    inquiry
                }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to submit form');
            }

            const formData = new FormData();
            formData.append("access_key", "7a6cefd8-1d60-46e1-bf19-4a34d18d5a37");
            formData.append("email", email);
            formData.append("name", name);
            formData.append("message", `Appointment scheduled for ${name} ${last_name} at ${street_address}, ${city}, ${zip_code}. Phone: ${phone}. Inquiry: ${inquiry} Source: ${source}.`);

            const emailResponse = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                body: formData,
            });

            console.log(emailResponse);

            if (!emailResponse.ok) {
                throw new Error('Failed to send email');
            }
    
            setStatus("Submit");
            setResult("Form Submitted Successfully");
            form.reset();
        } catch (error) {
            console.error("Error:", error);
            setResult("Failed to submit form");
        }
    };
    

    return (
        <div className='appointments'>
            <div className="appointment-col">
                <h3>Get In Touch With Our Team</h3>
        <ul>
            <li><img src={mail_icon} alt="" />JCTKServices@gmail.com</li>
            <li><img src={phone_icon} alt="" />813-770-1065</li>
            <li><img src={location_icon} alt="" />Bradenton, Florida , United States</li>
        </ul>
            </div>
            <div className="appointment-col">
                <h2 className="form-title">Schedule Appointment</h2>
                <form onSubmit={handleSubmit}>
                    <label>First Name *</label>
                    <input type="text" name="first_name" placeholder="First Name" required/>
                    <label>Last Name *</label>
                    <input type="text" name="last_name" placeholder="Last Name" required />
                    <label>Email *</label>
                    <input type="email" name="email" placeholder="Email" required />
                    <label>Phone *</label>
                    <input type="tel" name="phone" placeholder="Phone" required/>
                    <label>Street Address *</label>
                    <input type="text" name="street_address" required/>
                    <label>City *</label>
                    <input type="text" name="city" required/>
                    <label>Zip Code *</label>
                    <input type="text" name="zip_code" required/>
                    <label>How did you hear about us? *</label>
                    <input type="text" name="source" required/>
                    <label>How can we help you ? *</label>
                    <textarea name="inquiry" placeholder="Message" required></textarea>
                    <button type="submit" className='btn dark-btn'>Submit</button>
                </form>
                {result && <p>{result}</p>}
            </div>
        </div>
    );
}

export default Appointments;
