import React from 'react';
import './banner.css';
import dark_arrow from '../../assests/dark-arrow.png';
import { scroller } from 'react-scroll';

const Banner = () => {
    const scrollToContact = () => {
        scroller.scrollTo('contact', {
            smooth: true,
            offset: -260,
            duration: 500,
        });
    };

    return (
        <div className='banner'>
            <div className='hero-text'>
                <h1>Expert Handyman Services at Your Doorstep!</h1>
                <p>Reliable, Affordable, and Skilled - Your Home's Best Friend</p>
                <button className='btn' onClick={scrollToContact}>
                    Request A Free Quote <img src={dark_arrow} alt="" />
                </button>
            </div>
        </div>
    );
}

export default Banner;
