import React from 'react';
import Appointment from '../pages/Appointment/Appointment';
import Navbar from '../Components/Navbar/Navbar';
import Footer from '../Components/Footer/Footer';
import '../Components/Footer/footer.css';
import './SinglePage.css';

const SinglePage = () => {
    return (
        <div className='singlepage'>
            <Navbar />
            <Appointment
                heroProps={{
                    title: "Book an Appointment",
                    subtitle: "Fill out the form below to schedule an appointment with us."
                }}
            />
            <Footer />
        </div>
    );
}

export default SinglePage;
