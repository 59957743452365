import React, { useEffect, useState } from 'react';
import client, { urlFor } from '../../client'; // Adjust the import path to your Sanity client
import './about.css';

const About = () => {
    const [aboutData, setAboutData] = useState(null);

    useEffect(() => {
        const fetchAboutData = async () => {
            const query = `*[_type == "about"]{
                title,
                image,
                content
            }[0]`; // Fetch the first document
            const result = await client.fetch(query);
            setAboutData(result);
        };

        fetchAboutData();
    }, []);

    if (!aboutData) return <div>Loading...</div>;

    return (
        <div className='about'>
            <div className="about-left">
                <img src={urlFor(aboutData.image).url()} className='about-img' alt="about-img" />
            </div>
            <div className="about-right">
                <h2>{aboutData.title}</h2>
                {aboutData.content.map((block, index) => (
                    <p key={index}>{block.children[0].text}</p>
                ))}
            </div>
        </div>
    );
}

export default About;
